import { marketplaceURL } from 'shared/helpers/urls';

export const CITY_URLS = {
  BERLIN: {
    marketplace: marketplaceURL('/catering/berlin'),
  },
  MUNICH: {
    marketplace: marketplaceURL('/catering/muenchen'),
  },
  HAMBURG: {
    marketplace: marketplaceURL('/catering/hamburg'),
  },
  DUESSELDORF: {
    marketplace: marketplaceURL('/catering/duesseldorf'),
  },
  KOELN: {
    marketplace: marketplaceURL('/catering/koeln'),
  },
  STUTTGART: {
    marketplace: marketplaceURL('/catering/stuttgart'),
  },
  FRANKFURT_MAIN: {
    marketplace: marketplaceURL('/catering/frankfurt-am-main'),
  },
  DEFAULT: {
    marketplace: marketplaceURL('/catering'),
    breakfast: '/catering-berlin-fruehstueck-buffet',
    buffet: '/buffet',
    fingerFood: '/fingerfood',
    gourmet: '/fine-dining',
  },
};

export const CITIES = [
  {
    name: 'berlin',
    url: '/catering-berlin',
    events: [
      {
        name: 'event',
        link: '/catering-berlin-event',
      },
      {
        name: 'lunch',
        link: '/berlin-catering-lunch',
      },
      {
        name: 'breakfast',
        link: '/catering-berlin-fruehstueck',
      },
      {
        name: 'christmas',
        link: '/catering-berlin-weihnachtsfeier',
      },
      {
        name: 'buffet',
        link: '/catering-berlin-buffet',
      },
      {
        name: 'fingerFood',
        link: '/catering-berlin-fingerfood',
      },
      {
        name: 'gourmet',
        link: '/catering-berlin-fine-dining',
      },
    ],
  },
  {
    name: 'muenchen',
    url: '/catering-muenchen',
    events: [
      {
        name: 'event',
        link: '/catering-muenchen-event',
      },
      {
        name: 'lunch',
        link: '/catering-muenchen-lunch',
      },
      {
        name: 'breakfast',
        link: '/catering-muenchen-fruehstueck',
      },
      {
        name: 'christmas',
        link: '/catering-muenchen-weihnachtsfeier',
      },
      {
        name: 'buffet',
        link: '/catering-muenchen-buffet',
      },
      {
        name: 'fingerFood',
        link: '/catering-muenchen-fingerfood',
      },
      {
        name: 'gourmet',
        link: '/catering-muenchen-fine-dining',
      },
    ],
  },
  {
    name: 'hamburg',
    url: '/catering-hamburg',
    events: [
      {
        name: 'event',
        link: '/catering-hamburg-event',
      },
      {
        name: 'lunch',
        link: '/catering-hamburg-lunch',
      },
      {
        name: 'breakfast',
        link: '/catering-hamburg-fruehstueck',
      },
      {
        name: 'christmas',
        link: '/catering-hamburg-weihnachtsfeier',
      },
      {
        name: 'buffet',
        link: '/catering-hamburg-buffet',
      },
      {
        name: 'fingerFood',
        link: '/catering-hamburg-fingerfood',
      },
      {
        name: 'gourmet',
        link: '/catering-hamburg-fine-dining',
      },
    ],
  },
  {
    name: 'frankfurt-am-main',
    url: '/catering-frankfurt',
    events: [
      {
        name: 'event',
        link: '/catering-frankfurt-event',
      },
      {
        name: 'breakfast',
        link: '/catering-frankfurt-fruehstueck',
      },
      {
        name: 'christmas',
        link: '/catering-frankfurt-weihnachtsfeier',
      },
      {
        name: 'buffet',
        link: '/catering-frankfurt-buffet',
      },
      {
        name: 'fingerFood',
        link: '/catering-frankfurt-fingerfood',
      },
      {
        name: 'gourmet',
        link: '/catering-frankfurt-fine-dining',
      },
    ],
  },
];
