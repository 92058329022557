import React from 'react';

import FullWidthSlider from 'shared/components/FullWidthSlider';
import ImageCard from 'static_pages/components/ImageCard';

export type ImageCardSliderItem = React.ComponentProps<typeof ImageCard> & {
  key: string;
};

interface Props {
  items: ImageCardSliderItem[];
}

const ImageCardSlider: React.FC<Props> = ({ items }) => {
  return (
    <FullWidthSlider scrollButtonOffset={{ top: 80 }}>
      {items.map((item) => (
        <ImageCard
          key={item.key}
          href={item.href}
          cta={item.cta}
          ctaHighlight={item.ctaHighlight}
          pillText={item.pillText}
          imageSrc={item.imageSrc}
          ctaTextAlign={item.ctaTextAlign}
        />
      ))}
    </FullWidthSlider>
  );
};

export default ImageCardSlider;
