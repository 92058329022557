import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button } from '@heycater/design-system';
import { BoxProps } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import styled, { css } from 'styled-components';

import { useWindowSize } from 'shared/hooks/useWindowSize';

const DEFAULT_SCROLL_AMOUNT_IN_PIXEL = 300;

type Position = 'left' | 'right';

interface Props extends BoxProps {
  gap?: number;
  scrollAmount?: number;
  scrollButtonOffset?: { bottom: number } | { top: number };
}

const FullWidthSlider: React.FC<Props> = ({
  children,
  scrollAmount = DEFAULT_SCROLL_AMOUNT_IN_PIXEL,
  scrollButtonOffset,
  gap = 3,
  ...wrapperBoxProps
}) => {
  const ref = useRef<null | HTMLDivElement>(null);
  const [isScrollable, setScrollable] = useState(true);
  const { width: windowWidth } = useWindowSize();

  const buttonTopOffset =
    scrollButtonOffset && 'top' in scrollButtonOffset && scrollButtonOffset.top;

  const buttonBottomOffset =
    scrollButtonOffset &&
    'bottom' in scrollButtonOffset &&
    scrollButtonOffset.bottom;

  useEffect(() => {
    const isCurrentlyScrollable = ref?.current?.scrollHeight
      ? ref.current?.scrollWidth > ref.current?.clientWidth
      : true;

    setScrollable(isCurrentlyScrollable);
  }, [windowWidth]);

  const createScrollHandler = useCallback(
    (direction) => () => {
      const element = ref.current;
      if (!element) {
        return;
      }
      const scrollLeft = element.scrollLeft;

      element.scrollTo({
        left:
          direction === 'left'
            ? scrollLeft - scrollAmount
            : scrollLeft + scrollAmount,
        behavior: 'smooth',
      });
    },
    [scrollAmount]
  );

  return (
    <Box position="relative">
      <WhiteFade position="left" />
      {isScrollable && (
        <ScrollButtonWrapper
          bottom={buttonBottomOffset || 0}
          top={buttonTopOffset || 0}
          position="left"
        >
          <ScrollButton
            direction="left"
            onClick={createScrollHandler('left')}
          />
        </ScrollButtonWrapper>
      )}

      <ScrollableSection
        justifyContent={isScrollable ? 'start' : 'center'}
        px={2}
        gap={gap}
        ref={ref}
        {...wrapperBoxProps}
      >
        {children}
      </ScrollableSection>

      {isScrollable && (
        <ScrollButtonWrapper
          bottom={buttonBottomOffset || 0}
          top={buttonTopOffset || 0}
          position="right"
        >
          <ScrollButton
            direction="right"
            onClick={createScrollHandler('right')}
          />
        </ScrollButtonWrapper>
      )}
      <WhiteFade position="right" />
    </Box>
  );
};

const ScrollableSection = styled(Box)<{ gap?: number }>`
  width: 100%;
  overflow: auto;
  display: flex;
  gap: ${({ gap }) => (gap ? gap * 8 : 0)}px;
  margin: 0 auto;

  ${({ theme }) => theme.breakpoints.up('md')} {
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

const ScrollButton = ({
  onClick,
  direction,
}: {
  onClick: () => void;
  direction: Position;
}) => (
  <CircularButton
    flip={direction === 'left'}
    variant="tertiary"
    rounded
    onClick={onClick}
  >
    <ArrowForwardIos />
  </CircularButton>
);

const CircularButton = styled(Button)<{ flip?: boolean }>`
  width: 50px;
  height: 50px;
  background-color: white;
  border-color: white;
  transform: ${({ flip }) => flip && 'rotate(-180deg)'};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 55px;
    height: 55px;
  }
`;

const ScrollButtonWrapper = styled.div<{
  position: Position;
  top?: number;
  bottom?: number;
}>`
  position: absolute;
  display: none;
  z-index: 11;

  ${({ top }) =>
    top &&
    css`
      top: ${top}px;
    `}

  ${({ bottom }) =>
    bottom &&
    css`
      bottom: ${bottom}px;
    `}

  ${({ top, bottom }) =>
    !bottom &&
    !top &&
    css`
      top: 0;
      bottom: 0;
    `}
  
  display: flex;
  align-items: center;
  right: ${({ position }) => (position === 'right' ? '-80px' : 'none')};
  left: ${({ position }) => (position === 'left' ? '-80px' : 'none')};

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: block;
    padding: ${({ theme }) => theme.spacing(0, 4)};
  }
`;

const WhiteFade = styled.div<{ position: Position }>`
  display: none;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: block;
    width: 88px;
    height: 100%;
    right: ${({ position }) => (position === 'right' ? '-60px' : 'none')};
    left: ${({ position }) => (position === 'left' ? '-60px' : 'none')};
    top: 0;
    position: absolute;
    pointer-events: none;
    bottom: 0;
    z-index: 10;
    background: linear-gradient(
      270deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );

    transform: ${({ position }) =>
      position === 'left' ? 'rotate(-180deg)' : 'none'};
  }
`;

export default FullWidthSlider;
