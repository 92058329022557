import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useCitySelect from 'shared/hooks/useCitySelect';

function CitySelectorPills() {
  const { t } = useTranslation('static_pages');
  const { selectCityOptions, handleSelectCity } = useCitySelect();

  return (
    <StyledFooterCTA as="section">
      <Box maxWidth={700} mx="auto">
        <Title my={2} component="h3" textAlign="center" color="inherit">
          {t('homepage.citySelectorPills.title')}
        </Title>
        <FlexWrapper>
          {selectCityOptions.map((option) =>
            option.value === 'other' ? (
              <CityPill
                outlined
                onClick={() => handleSelectCity(option)}
                key={option.value}
              >
                {t('homepage.citySelectorPills.otherCity')}
              </CityPill>
            ) : (
              <CityPill
                key={option.value}
                onClick={() => handleSelectCity(option)}
              >
                {option.label}
              </CityPill>
            )
          )}
        </FlexWrapper>
      </Box>
    </StyledFooterCTA>
  );
}

const Title = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 172.5%;
  color: ${({ theme }) => theme.palette.secondary['500']};
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  row-gap: ${({ theme }) => theme.spacing(2)}px;
`;

const CityPill = styled.button<{ outlined?: boolean }>`
  padding: ${({ theme }) => theme.spacing(1, 3)};
  border-radius: 100px;
  background-color: ${({ outlined }) => (outlined ? `transparent` : '#F0F0F0')};
  border: ${({ outlined }) => (outlined ? `1px solid black` : 'none')};

  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  cursor: pointer;

  transition: background-color 300ms;

  &:hover {
    background-color: ${({ outlined }) =>
      outlined ? `rgba(0,0,0,0.05)` : 'rgb(233, 232, 232)'};
  }
`;

const StyledFooterCTA = styled.div`
  padding: ${(props) => props.theme.spacing(2, 0, 4, 0)};
  color: white;
  position: relative;
`;

export default CitySelectorPills;
