import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { persistCityInLocalStorage } from 'shared/helpers/cities';
import useCities from 'shared/hooks/useCities';
import Berlin from 'shared/svg/cities/Berlin';
import Cologne from 'shared/svg/cities/Cologne';
import Dusseldorf from 'shared/svg/cities/Dusseldorf';
import FrankfurtMain from 'shared/svg/cities/FrankfurtMain';
import Hamburg from 'shared/svg/cities/Hamburg';
import Munich from 'shared/svg/cities/Munich';
import Other from 'shared/svg/cities/Other';
import Stuttgart from 'shared/svg/cities/Stuttgart';
import { buildFunnelUrl } from 'static_pages/marketing_pages/helpers';

export default function useCitySelect({ excludeOther = false } = {}) {
  const { cities: cityTranslations } = useCities();
  const { t } = useTranslation();
  const router = useRouter();

  const selectCityOptions = [
    {
      value: 'muenchen',
      label: cityTranslations['muenchen'],
      imageComponent: Munich,
    },
    {
      value: 'berlin',
      label: cityTranslations['berlin'],
      imageComponent: Berlin,
    },
    {
      value: 'frankfurt-am-main',
      label: cityTranslations['frankfurt-am-main'],
      imageComponent: FrankfurtMain,
    },
    {
      value: 'hamburg',
      label: cityTranslations['hamburg'],
      imageComponent: Hamburg,
    },
    {
      value: 'duesseldorf',
      label: cityTranslations['duesseldorf'],
      imageComponent: Dusseldorf,
    },
    {
      value: 'koeln',
      label: cityTranslations['koeln'],
      imageComponent: Cologne,
    },
    {
      value: 'stuttgart',
      label: cityTranslations['stuttgart'],
      imageComponent: Stuttgart,
    },
  ];

  if (!excludeOther) {
    selectCityOptions.push({
      value: 'other',
      label: t('common:labels.other'),
      imageComponent: Other,
    });
  }

  const handleSelectCity = ({ value }: { value: string }) => {
    persistCityInLocalStorage(value);
    return router.push(buildFunnelUrl({ city: value, router }));
  };

  return {
    selectCityOptions,
    handleSelectCity,
  };
}
