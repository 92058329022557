import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

const COUNTRY_MAPPER = {
  'da-dk': 'common:citiesDenmark',
  'en-uk': 'common:citiesUK',
  'de-at': 'common:citiesAustria',
  'de-ch': 'common:citiesSwitzerland',
  'de-de': 'common:cities',
  'en-de': 'common:cities',
  en: 'common:cities',
  de: 'common:cities',
  da: 'common:citiesDenmark',
};

export default function useCities() {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const countryCities = locale
    ? COUNTRY_MAPPER[locale as keyof typeof COUNTRY_MAPPER]
    : [];

  const cities = t(`${countryCities}`, { returnObjects: true }) as {
    [key: string]: string;
  };
  const cityNames = Object.values(cities).sort();
  const cityKeys = Object.keys(cities);

  return {
    cities,
    cityKeys,
    cityNames,
  };
}
