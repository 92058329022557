import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  href: string;
  ctaHighlight?: string;
  cta: string;
  imageSrc: string;
  pillText?: string;
  ctaTextAlign?: string;
}

const CARD_WIDTH = 280;

function ImageCard({
  href,
  ctaHighlight,
  cta,
  imageSrc,
  pillText,
  ctaTextAlign = 'center',
}: Props) {
  const { t } = useTranslation('dynamic_landing_page');
  const WrapperComponent = href ? Link : React.Fragment;
  return (
    <WrapperComponent href={href} passHref>
      <StyledLink target="_blank">
        <Box position="relative">
          <ImageWrapper>
            <Image
              alt=""
              src={imageSrc}
              objectFit="cover"
              width={CARD_WIDTH}
              height={210}
            />
          </ImageWrapper>

          {pillText && (
            <StyledPill>
              <Typography lineHeight={1} variant="caption" textAlign>
                {pillText}
              </Typography>
            </StyledPill>
          )}
        </Box>

        {cta ? (
          <Box pt={1.5} textAlign={ctaTextAlign}>
            <TypographyCTA component="span" className="card-title">
              {cta ? cta : t('CTA')}
            </TypographyCTA>
            {ctaHighlight && (
              <TypographyCompany className="card-title" component="span">
                {' '}
                {ctaHighlight}
              </TypographyCompany>
            )}
          </Box>
        ) : null}
      </StyledLink>
    </WrapperComponent>
  );
}

const TypographyCTA = styled(Typography)<{ component?: string }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 158%;
`;

const TypographyCompany = styled(TypographyCTA)`
  color: #76a334;
`;

const StyledLink = styled.a`
  text-decoration: inherit;
  color: inherit;
  width: ${CARD_WIDTH}px;

  img {
    width: 100%;
    display: block;
    position: relative;
    transition: transform 0.5s ease, filter 0.5s ease;
  }

  &:hover img {
    filter: brightness(0.9);
    transform: scale(1.1);
  }

  &:hover .card-title {
    text-decoration: underline;
  }
`;

const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 280px;
  height: 210px;
  border-radius: ${(props) => props.theme.spacing(1.5)}px;
`;

const StyledPill = styled(Box)`
  position: absolute;
  top: ${(props) => props.theme.spacing(1)}px;
  right: ${(props) => props.theme.spacing(1)}px;
  padding: ${(props) => props.theme.spacing(0.5, 1)};
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  border-radius: ${(props) => props.theme.spacing(8)}px;
`;

export default ImageCard;
