import React, { useMemo } from 'react';
import { Box, Typography } from '@heycater/design-system';
import { BoxProps } from '@material-ui/core';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { getContentfulImageUrl } from 'hub/helpers/contentful';
import { IHomePageFields } from 'lib/types/generated/contentful';
import { useBreakpointUp } from 'qualification/hooks/useBreakpoints';
import ImageCardSlider, {
  ImageCardSliderItem,
} from 'static_pages/components/ImageCardSlider';
import CitySelectorPills from 'static_pages/home/components/CitySelectorPills';
import { CITY_URLS } from 'static_pages/home/constants';
import { buildFunnelUrl } from 'static_pages/marketing_pages/helpers';
import Container from 'system/components/Container';

interface Props extends BoxProps {
  landingPageCards: IHomePageFields['landingPageCards'];
}

function SEOCopy({ landingPageCards, ...boxProps }: Props) {
  const { t } = useTranslation('static_pages');
  const router = useRouter();
  const mdUp = useBreakpointUp('md');

  const landingPageCardItems = useMemo(() => {
    const cards: ImageCardSliderItem[] = [];

    landingPageCards?.forEach((page) => {
      let imageSrc = null;

      if (!page?.fields) {
        return;
      }

      if ('section1Image' in page.fields && page.fields.section1Image) {
        imageSrc = getContentfulImageUrl(page.fields.section1Image);
      }
      if ('headerImage' in page.fields && page.fields.headerImage) {
        imageSrc = getContentfulImageUrl(page.fields.headerImage);
      }

      if (!imageSrc || !page.fields.heroTitle) {
        return;
      }

      cards.push({
        cta: page.fields.heroTitle,
        href: page.fields.slug,
        key: page.fields.slug,
        imageSrc,
      });
    });
    return cards;
  }, [landingPageCards]);

  return (
    <Box as="section" id="seocopy-section" {...boxProps}>
      <Container size="xsm">
        <Typography
          variant="h4"
          component="h2"
          fontSize={{ xs: 24, sm: 30, md: 36 }}
          mb={{ xs: 3, sm: 5, md: 8 }}
          textAlign="center"
        >
          {t('homepage.businessCatering.title')}
        </Typography>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems="center"
        >
          <Box
            minWidth={322}
            maxHeight={306}
            width={{ xs: '100%', md: undefined }}
            mb={{ xs: 3, md: 0 }}
            position="relative"
            borderRadius={8}
            overflow="hidden"
          >
            <Image
              alt=""
              src="/artifacts/images/homepage/seo-section/seo-section-image.webp"
              height={320}
              width={mdUp ? 320 : 500}
              objectFit="cover"
              layout={'responsive'}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            pl={{ md: 4 }}
            height="100%"
          >
            <CopySectionTitle variant="h5" mb={2}>
              {t('homepage.businessCatering.services.title')}
            </CopySectionTitle>
            <Box>
              <CopySectionText variant="body2" component="p">
                {t('homepage.businessCatering.services.description')}
              </CopySectionText>
            </Box>
          </Box>
        </Box>

        <Box mt={3}>
          <CopySectionTitle variant="h5" mb={2}>
            {t('homepage.businessCatering.yourCity.title')}
          </CopySectionTitle>
          <Box>
            <CopySectionText variant="body2" component="p" mb={2}>
              {t('homepage.businessCatering.yourCity.description.a')}
            </CopySectionText>
            <CopySectionText variant="body2" component="p">
              {t('homepage.businessCatering.yourCity.description.b')}
              <Link href={CITY_URLS.BERLIN.marketplace} passHref>
                <a>{t('common:cities.berlin')}</a>
              </Link>
              ,{' '}
              <Link href={CITY_URLS.MUNICH.marketplace} passHref>
                <a>{t('common:cities.muenchen')}</a>
              </Link>
              ,{' '}
              <Link href={CITY_URLS.HAMBURG.marketplace} passHref>
                <a>{t('common:cities.hamburg')}</a>
              </Link>{' '}
              {t('homepage.businessCatering.yourCity.description.and')}
              <Link href={CITY_URLS.FRANKFURT_MAIN.marketplace} passHref>
                <a>{t('common:cities.frankfurt-am-main')}</a>
              </Link>
              {t('homepage.businessCatering.yourCity.description.c')}
              <Link href={buildFunnelUrl({ router })} passHref>
                <a>
                  {t('homepage.businessCatering.yourCity.description.request')}
                </a>
              </Link>
              {t('homepage.businessCatering.yourCity.description.d')}
            </CopySectionText>
          </Box>
        </Box>
        <div id="citySelectSection">
          <CitySelectorPills />
        </div>
        <Box mt={4}>
          <CopySectionTitle variant="h5" mb={2}>
            {t('homepage.businessCatering.selection.title')}
          </CopySectionTitle>
          <Box>
            <CopySectionText variant="body2" component="p" mb={2}>
              {t('homepage.businessCatering.selection.description.a')}
              <Link href={CITY_URLS.DEFAULT.fingerFood} passHref>
                <a>
                  {' '}
                  {t(
                    'homepage.businessCatering.selection.description.fingerFood'
                  )}
                </a>
              </Link>
              ,{' '}
              <Link href={CITY_URLS.DEFAULT.breakfast} passHref>
                <a>
                  {t(
                    'homepage.businessCatering.selection.description.breakfast'
                  )}
                </a>
              </Link>
              {t('homepage.businessCatering.selection.description.b')}
              <Link href={CITY_URLS.DEFAULT.gourmet} passHref>
                <a>
                  {t('homepage.businessCatering.selection.description.gourmet')}
                </a>
              </Link>
              {t('homepage.businessCatering.selection.description.and')}
              <Link href={CITY_URLS.DEFAULT.buffet} passHref>
                <a>
                  {' '}
                  {t('homepage.businessCatering.selection.description.buffet')}
                </a>
              </Link>
              {'. '}
            </CopySectionText>
            <CopySectionText variant="body2" component="p" mb={2}>
              {t('homepage.businessCatering.selection.description.c')}
            </CopySectionText>
            <CopySectionText variant="body2" component="p" mb={2}>
              {t('homepage.businessCatering.selection.description.d')}
            </CopySectionText>
          </Box>
        </Box>
      </Container>
      <Box mt={5}>
        <ImageCardSlider items={landingPageCardItems} />
      </Box>

      <Container size="xsm">
        <Box mt={5}>
          <CopySectionTitle variant="h5" mb={2}>
            {t('homepage.businessCatering.event.title')}
          </CopySectionTitle>
          <Box>
            <CopySectionText variant="body2" component="p" mb={2}>
              {t('homepage.businessCatering.event.description.a')}
            </CopySectionText>
            <CopySectionText variant="body2" component="p" mb={2}>
              {t('homepage.businessCatering.event.description.b')}
            </CopySectionText>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

const CopySectionTitle = styled(Typography)`
  font-weight: 700;
  font-size: 14px;
  line-height: 27px;
  margin: 0px;
  display: inline-block;
`;

const CopySectionText = styled(Typography)`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

export default SEOCopy;
